import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './app/App';
import * as serviceWorker from './serviceWorker';
// import { createRoot } from 'react-dom/client';

ReactDOM.render(<App />, document.getElementById('root'));

/** Commeting this for DE123897 to avoid the redirection issue on First Click. 
 * Need to update the DOM library and then use this apporach ***/
// const container = document.getElementById('root');
// const root = createRoot(container); // createRoot(container!) if you use TypeScript
// root.render(<App/>);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
